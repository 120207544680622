import React, { useState, useEffect, memo, useCallback } from "react"
import { API, graphqlOperation } from "aws-amplify"
import type { SubscriptionType } from "@lesmills/gatsby-theme-common"
import type { ChangeSubscriptionPageType } from "../../types/ChangeSubscriptionPageType"
import type { CancelSubscriptionPopupType } from "../../types/CancelSubscriptionPopupType"
import type { MyAccountPageType } from "../../types/MyAccountPageType"
import type { EmailNewsletterType } from "../types/EmailNewsletterType"
import type { MemberPromoPopupType } from "../types/MemberPromoPopupType"
import type { AllLayoutsType, UserType } from "@lesmills/gatsby-theme-common"
import { RichText } from "prismic-reactjs"
import {
  cancelSubscription,
  immediateCancelSubscription,
} from "../../graphql/cancelSubscription"
import { restartSubscription } from "../../graphql/restartSubscription"
import { changeSubscriptionChargifyJs } from "../../graphql/changeSubscriptionChargifyJs"
import { deleteSubscription } from "../../graphql/deleteSubscription"
import {
  LoadingIndicator,
  formatDateTime,
  getLocalStorage,
  NEW_SUBSCRIPTION_KEY,
  JWT_TOKEN,
  deleteLocalStorage,
  isEmpty,
  isBrowser,
  getParamsFromURL,
  ROUTES,
  Button,
  handleErrorWithPrismic,
  SENTRY_ACTIONS,
  linkResolver,
  client,
  setLocalStorage,
  USER_INFO_KEY,
  isImmediatelyCanceled,
  scrollToTop,
  DATETIME_FORMAT,
  isExpiredOffer,
  getCartProduct,
  getCreateAccountRoute,
  captureException,
  SUBSCRIPTION_STATES,
  ON_HOLD_STATES,
  isActiveRollover,
  formatPlusTaxText,
  isResellerUser,
  isResellerCustomerUser,
  isIAP,
  Spinner,
  IS_MEMBER_PROMO_DISPLAYED,
  getConvertedPlanPeriod,
  trackCancelSubscription,
} from "@lesmills/gatsby-theme-common"

import Container from "../Container"

// import { useRef } from "react"
import { getProductById } from "../../graphql/getProductById"
import { getPromoCodeInformation } from "../../graphql/getPromoCodeInformation"
import { updateSubscription } from "../../graphql/updateSubscription"
import Popup from "../Popup"
import { navigate } from "gatsby"
import { updateSubscriptionPreference } from "../../graphql/updateSubscriptionPreferences"
import { checkStandardSubscriptionCountry } from "../../utils/LookupLocationUtil"
import { getPaymentInfo } from "../../utils/payment"
import parseJwt from "../../utils/userInfo"
import { getOffer } from "../../utils/memberPromo"

import loadable from "@loadable/component"

const NotificationToast = loadable(
  () => import("@lesmills/gatsby-theme-common"),
  {
    fallback: <Spinner />,
    resolveComponent: components => components.NotificationToast,
  }
)

const FitnessProfileSection = loadable(
  () => import("./FitnessProfileSection"),
  {
    fallback: <Spinner />,
  }
)
const PaymentDetailSection = loadable(() => import("./PaymentDetailSection"), {
  fallback: <Spinner />,
})
const PersonalInfoSection = loadable(() => import("./PersonalInfoSection"), {
  fallback: <Spinner />,
})
const EmailReferencesSection = loadable(
  () => import("./EmailReferencesSection"),
  { fallback: <Spinner /> }
)
const SubscriptionDetailSection = loadable(
  () => import("./SubscriptionDetailSection"),
  { fallback: <Spinner /> }
)
const RestartSubscription = loadable(() => import("../RestartSubscription"), {
  fallback: <Spinner />,
})
const ChangeSubscriptionTypeModal = loadable(
  () => import("../ChangeSubscriptionTypeModal"),
  { fallback: <Spinner /> }
)
const CancellationSuspendedModal = loadable(
  () => import("../CancellationSuspendedModal"),
  {
    fallback: <Spinner />,
  }
)

const CancellationConfirmModal = loadable(
  () => import("../CancellationConfirmModal"),
  {
    fallback: <Spinner />,
  }
)

const MemberPromoModal = loadable(() => import("../MemberPromoModal"), {
  fallback: <Spinner />,
})

type Props = {|
  dataPrismic?: MyAccountPageType,
  subscription: SubscriptionType,
  availableSubscriptions: Array<SubscriptionType>,
  changeSubscriptionData: ChangeSubscriptionPageType,
  cancelSubscriptionData: CancelSubscriptionPopupType,
  newsletterData: EmailNewsletterType,
  layoutData: AllLayoutsType,
  loading: Boolean,
  lang: string,
  user: UserType,
  location?: Object,
  memberPromoPopupData: MemberPromoPopupType,
|}

const MyAccount = ({
  dataPrismic = {},
  availableSubscriptions = [],
  changeSubscriptionData = {},
  cancelSubscriptionData = {},
  newsletterData = {},
  lang,
  user = {},
  layoutData = {},
  location = {},
  memberPromoPopupData = {},
}: Props) => {
  const [loading, setLoading] = useState(true)
  const [userData, setUser] = useState({})
  const [responseStatus, setResponseStatus] = useState({})
  const [currentSubscription, setCurrentSubscription] = useState({})
  const [nextSubscription, setNextSubscription] = useState({})
  const [isProcessing, setIsProcessing] = useState(false)
  const [rolloverSubscription, setRolloverSubscription] = useState({})
  const [
    isOpenedRestartSubscription,
    setIsOpenedRestartSubscription,
  ] = useState(false)
  const [
    isOpenedChangeSubscriptionType,
    setIsOpenedChangeSubscriptionType,
  ] = useState(false)
  const [isOpenedMemberOfferModal, setIsOpenedMemberOfferModal] = useState(
    false
  )
  const [memberPromoIsValid, setMemberPromoIsValid] = useState(false)
  const [firstMemberPromoCode, setFirstMemberPromoCode] = useState("")

  const userCountry = user.addressCountry
  // https://lesmillsinternational.atlassian.net/browse/LA-1221
  // The price of subscription in US and CA is not included tax
  // Add "plus Tax" right after price in US and CA
  const isNotIncludedTax = checkStandardSubscriptionCountry(userCountry)

  const plusTaxText = formatPlusTaxText(layoutData)

  const [isOpenedCancelSubscription, setIsOpenedCancelSubscription] = useState(
    false
  )
  const [
    isOpenedContinueCurrentSubscription,
    setIsOpenedContinueCurrentSubscription,
  ] = useState(false)

  const [
    isOpenedSubscriptionSuspended,
    setIsOpenedSubscriptionSuspended,
  ] = useState(false)

  const [isShowResumeSubscription, setShowResumeSubscription] = useState(false)

  const notifcationRender = message => {
    let node = message

    if (!node) {
      node = (
        <RichText
          render={on_hold_cancelled_subscription_notification?.raw}
          linkResolver={linkResolver}
        />
      )
    }

    if (typeof render === "string") {
      node = <p>{render}</p>
    }

    return node
  }

  const renderNotificationToast = () => {
    let notificationData = null

    /* AB2B-371: Show notification to ask existing users in state-required countries update state */
    if (userData.isForceUpdateAddress) {
      notificationData = {
        render: layoutData.required_state_notification && (
          <RichText
            render={layoutData.required_state_notification.raw}
            linkResolver={linkResolver}
          />
        ),
        showHideIcon: false,
        onClose: () => handleCloseResumeSubscription(),
        type: "error",
      }
    }

    if (Object.keys(responseStatus).length > 0) {
      notificationData = {
        render: notifcationRender(responseStatus?.message),
        showHideIcon: true,
        onClose: () => handleHideNotification(),
        type: responseStatus?.type || "reminder",
      }
    }
    if (isShowResumeSubscription && !isIAPSubs) {
      notificationData = {
        render: (
          <RichText
            render={resume_subscription_success?.raw}
            linkResolver={linkResolver}
          />
        ),
        showHideIcon: true,
        onClose: () => handleCloseResumeSubscription(),
        type: "error",
      }
    }

    if (!notificationData) {
      return null
    }

    const { render, showHideIcon, onClose, type } = notificationData

    return (
      <NotificationToast
        children={render}
        showHideIcon={showHideIcon}
        handleHideToast={onClose}
        type={type}
        classNames={{
          wrapper: " mt-20",
        }}
        testId="my-account-notification"
      />
    )
  }

  const step = getParamsFromURL("step")
  const isReseller = isResellerUser(user)
  const isResellerCustomer = isResellerCustomerUser(user)
  const isIAPSubs = isIAP(user)

  const isChangedSubscriptionSuccess = getLocalStorage(NEW_SUBSCRIPTION_KEY)
    .isChangedSubscriptionSuccess
  const {
    cancel_subscription_button_label = {},
    title = {},
    body = [],
    get_personal_information_error = {},
    cancelled_subscription_notification = { text: "" },
    suspended_subscription_notification_rc = { text: "" },
    fully_cancelled_subscription_notification = {},
    restart_subscription_now_label = {},
    restart_subscription_label = {},
    restart_subscription_reminder = {},
    change_subscription_success = {},
    resume_subscription_success = {},
    restart_subscription_error = {},
    change_subscription_error = {},
    lmod_dsdcs_err = {},
    continue_current_subscription_yes_button_label = {},
    continue_current_subscription_no_button_label = {},
    continue_current_subscription_confirm_message = { text: "" },
    continue_current_subscription_success = {},
    restart_subscription_success = {},
    lmod_gp_err = {},
    cancel_subscription_error = {},
    name_label = {},
    email_address_label = {},
    password_label = {},
    on_hold_cancelled_subscription_notification = {},
    lmod_usp_err = {},
  } = dataPrismic

  const [
    personalInfoSection = {},
    paymentDetailSection = {},
    emailReferencesSection = {},
    subscriptionDetailSection = {},
  ] = body

  const personalPrismicData = {
    personalInfoSection,
    name_label,
    email_address_label,
    password_label,
  }

  const {
    close_button_label = {},
    continue_button_label = {},
    no_button_label = {},
    skip_button_label = {},
    yes_button_label = {},
    submit_button_label = {},
    keep_button_label = {},
    back_button_label = {},
    lmod_ics_err = {},
    offer_button_label = {},
    display_offer_button = false,
  } = cancelSubscriptionData || {}
  const currentPaymentMethod = getPaymentInfo(userData)

  // Get common cancellation buttons
  const questionButtons = {
    close: close_button_label.text,
    continue: continue_button_label.text,
    no: no_button_label.text,
    yes: yes_button_label.text,
    skip: skip_button_label.text,
    submit: submit_button_label.text,
    keep: keep_button_label.text,
    back: back_button_label.text,
    offer: offer_button_label.text,
  }

  const { registered_message = [] } = newsletterData

  const removeParamRestart = () => {
    if (location.href.indexOf("step=restart") > -1) {
      navigate(ROUTES(lang).CUSTOMER_ACCOUNT)
    }
    return
  }

  const handleSetUser = res => {
    setUser(res)

    const lmodSubscription = res.lmodSubscription || {}
    const currentProduct = lmodSubscription.product || {}
    setCurrentSubscription(lmodSubscription)

    if (
      isDelayCanceled(lmodSubscription) ||
      isOnHoldSubscription(lmodSubscription) ||
      isImmediatelyCanceled(lmodSubscription)
    ) {
      renderCancelledSubscriptionNotification(lmodSubscription)
    }

    if (!isReseller && !isResellerCustomer && step === "restart") {
      // User need to be navigated to MEMBERSHIP page if sub was cancelled and step is restart
      if (isImmediatelyCanceled(lmodSubscription)) {
        navigate(ROUTES(lang).MEMBERSHIP)
        return
      } else {
        // In case restart action for not real canceled subscription, it will show popup
        setIsOpenedRestartSubscription(true)
      }
    }

    // LA-1407: Get next product by rollover_product_handle if active subscription and default_to_monthly = true
    const rolloverProductHandle =
      isActiveRollover(lmodSubscription, userCountry) &&
      currentProduct.rollover_product_handle

    const nextProductHandle = lmodSubscription.next_product_handle

    // check to avoid getNextProduct twice
    if (
      (nextProductHandle &&
        nextProductHandle !== currentSubscription.next_product_handle) ||
      rolloverProductHandle
      // https://lesmillsinternational.atlassian.net/browse/LA-1407
      // get rollover subscription for active subs if there is rolloverProductHandle
    ) {
      getNextProduct(
        nextProductHandle,
        res.addressCountry,
        rolloverProductHandle
      )
    }

    setLoading(false)
  }

  const getNextProduct = async (
    nextProductHandle,
    countryCode,
    rolloverProductHandle
  ) => {
    try {
      client()
      const res = await API.graphql(
        graphqlOperation(getProductById, {
          productId: nextProductHandle || rolloverProductHandle,
          countryCode,
        })
      )
      const nextProduct = (res && res.data && res.data.getProduct) || {}

      // LA-1407: Get next product by rollover_product_handle if active subscription and default_to_monthly = true
      // if changed subscription, the priority is getting changed product instead of rollover product
      if (nextProductHandle) {
        setNextSubscription(nextProduct)
        if (isChangedSubscriptionSuccess) {
          setResponseStatus({
            message: (
              <RichText
                render={change_subscription_success?.raw}
                linkResolver={linkResolver}
              />
            ),
            type: "success",
          })

          deleteLocalStorage(NEW_SUBSCRIPTION_KEY)
        }
      } else {
        setRolloverSubscription(nextProduct)
      }
    } catch (err) {
      handleErrorWithPrismic(
        err,
        lmod_gp_err.text,
        setResponseStatus,
        dataPrismic
      )
    }
  }

  const { product, current_period_ends_at, total_price_in_cents } =
    currentSubscription || {}

  const disableReactiveSubscription = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(updateSubscription, {
          reactive: false,
        })
      )
      if (response.errors && response.errors.length > 0) {
        // Send error to sentry
        captureException({
          action: SENTRY_ACTIONS.UPDATE_SUBSCRIPTION,
          requestVariables: { reactive: false },
          ...response.errors[0],
        })
      }
    } catch (err) {
      // Send error to sentry
      if (err.errors && err.errors.length > 0) {
        captureException({
          action: SENTRY_ACTIONS.UPDATE_SUBSCRIPTION,
          requestVariables: { reactive: false },
          ...err.errors[0],
        })
      }
    }
  }

  useEffect(() => {
    // Only set localstorage data when user has valid data

    if (user && !isEmpty(user)) {
      handleSetUser(user)
      if (user.chargifyPaymentProfile) {
        const savedUser = getLocalStorage(USER_INFO_KEY)
        setLocalStorage(USER_INFO_KEY, {
          ...savedUser,
          chargifyPaymentProfile: user.chargifyPaymentProfile,
        })
      }
    }
    // Show resume subscription notification
    // JIRA ticket: https://lesmillsinternational.atlassian.net/projects/LA/issues/LA-793
    if (
      !isEmpty(user) &&
      user.lmodSubscription &&
      user.lmodSubscription.reactive
    ) {
      setShowResumeSubscription(true)
      disableReactiveSubscription()
    }
  }, [user])

  /**
   * Cancel subscription
   */
  const handleCancelSubscription = async isFrom => {
    setIsProcessing(true)
    // Immediate cancel function only is called for on hold statues 'unpaid', 'expired', 'past_due', and 'on_hold' except 'failed'
    const isOnHold =
      !isFailedSubscription(currentSubscription) &&
      isOnHoldSubscription(currentSubscription)

    try {
      const response = await API.graphql(
        graphqlOperation(
          isOnHold ? immediateCancelSubscription : cancelSubscription
        )
      )

      // Handle errors with prismic data
      if (response.errors && response.errors.length > 0) {
        handleErrorWithPrismic(
          response.errors,
          (isOnHold ? lmod_ics_err : cancel_subscription_error).text,
          setResponseStatus,
          dataPrismic,
          SENTRY_ACTIONS.CANCEL_SUBSCRIPTION
        )
        setIsProcessing(false)
        if (isFrom === "memberPromoModal") {
          setIsOpenedMemberOfferModal(false)
        }
        setIsOpenedCancelSubscription(false)
        return
      }

      const cancelledSubscription = isOnHold
        ? response && response.data && response.data.immediateCancelSubscription
        : (response && response.data && response.data.cancelSubscription) || {}
      const newSubscription = isOnHold
        ? {
            ...currentSubscription,
            // Response success is boolean true: cancelled, false: keep current state
            state: cancelledSubscription
              ? SUBSCRIPTION_STATES.CANCELED
              : currentSubscription.state,
          }
        : {
            ...cancelledSubscription,
            total_price_in_cents,
            product: {
              ...cancelledSubscription.product,
            },
          }

      setCurrentSubscription(newSubscription)
      if (isFrom === "memberPromoModal") {
        setIsOpenedMemberOfferModal(false)
      }
      setIsOpenedCancelSubscription(false)
      setIsOpenedSubscriptionSuspended(true)
      renderCancelledSubscriptionNotification(newSubscription)
      setIsProcessing(false)
    } catch (err) {
      setIsProcessing(false)
      handleErrorWithPrismic(
        err.errors || [],
        (isOnHold ? lmod_ics_err : cancel_subscription_error).text,
        setResponseStatus,
        dataPrismic,
        SENTRY_ACTIONS.CANCEL_SUBSCRIPTION
      )
      if (isFrom === "memberPromoModal") {
        setIsOpenedMemberOfferModal(false)
      }
      setIsOpenedCancelSubscription(false)
    }
  }

  const isSubscriptionCanceled = subscription => {
    if (isDelayCanceled(subscription) || isImmediatelyCanceled(subscription)) {
      return true
    }
    return false
  }

  // Check whether subscription was delay canceled or not (status is not 'canceled')
  const isDelayCanceled = subscription => {
    return subscription.delayed_cancel_at
  }

  // Check whether subscription was failed or not (status will be 'failed')
  const isFailedSubscription = subscription => {
    return subscription.state === SUBSCRIPTION_STATES.FAILED
  }

  /**
   * All status 'unpaid', 'expired', 'past_due', 'failed' and 'on_hold' will be checked as On Hold
   * @param {Object} subscription
   */
  const isOnHoldSubscription = subscription => {
    return ON_HOLD_STATES.indexOf(subscription.state) > -1
  }

  const handleSubscription = () => {
    if (isSubscriptionCanceled(currentSubscription)) {
      if (isDelayCanceled(currentSubscription)) {
        // Open restart subscription popup
        setIsOpenedRestartSubscription(true)
      } else {
        // User need to be navigated to my. confirm plan page if sub was cancelled
        process.env.GATSBY_RT_26_07_2022_WINBACK === "true"
          ? isBrowser &&
            (window.location.href = `${process.env.GATSBY_GETTING_STARTED_URL}signup/select-plan`)
          : navigate(ROUTES(lang).MEMBERSHIP)
      }
    } else {
      validateMemberPromo()
      setIsOpenedCancelSubscription(true)
      trackCancelSubscription()
    }
  }

  /**
   * JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-806
   *  - Hide the Cancel Subscription button
   *  - Display the Retry Subscription button instead
   */
  const handleDeleteSubscription = async () => {
    setIsProcessing(true)
    try {
      const response = await API.graphql(graphqlOperation(deleteSubscription))
      if (
        (response.errors && response.errors.length > 0) ||
        (response && response.data && !response.data.deleteSubscription)
      ) {
        handleErrorWithPrismic(
          response.errors,
          lmod_dsdcs_err.text,
          handleError,
          dataPrismic,
          SENTRY_ACTIONS.DELETE_SUBSCRIPTION
        )
        setIsProcessing(false)
        return
      }
    } catch (err) {
      setIsProcessing(false)
      if (err.errors && err.errors.length > 0) {
        // Send error to sentry
        captureException({
          action: SENTRY_ACTIONS.DELETE_SUBSCRIPTION,
          ...err.errors[0],
        })
      }
    }

    const savedUser = getLocalStorage(USER_INFO_KEY)

    // Clear lmodSubscription on local storage when call deleteSubscription API
    setLocalStorage(USER_INFO_KEY, {
      ...savedUser,
      lmodSubscription: null,
    })
    // With Affiliate user has offer_id and cart_expiry_date has been reached
    if (isExpiredOffer(savedUser.cartExpiryDate)) {
      navigate(ROUTES(lang).CHECK_EMAIL)

      return
    }

    // With Retail user has product_handle && offer_id === null || Affiliate user cart_info && Cart_expiry_date has not been reached yet
    // Get product by offer_id or product_id
    getCartProduct(
      savedUser,
      () => {
        navigate(
          getCreateAccountRoute(!!user.cartOfferId, lang) + "?step=payment"
        )

        return
      },
      () => navigate(ROUTES(lang).MEMBERSHIP)
    )
    setIsProcessing(false)
  }

  const sectionStyles = {
    wrapper:
      " md:border-b-2 border-b border-gray-500 md:py-25 py-20 last:border-b-0",
  }

  const handleHideNotification = () => {
    setResponseStatus({})
  }

  const handleCloseResumeSubscription = () => {
    setShowResumeSubscription(false)
  }

  /**
   * Restart subscription
   */
  const handleRestartSubscription = async turnOffProcessing => {
    try {
      const response = await API.graphql(graphqlOperation(restartSubscription))

      // Handle errors with prismic data
      if (response.errors && response.errors.length > 0) {
        handleErrorWithPrismic(
          response.errors,
          restart_subscription_error.text,
          setResponseStatus,
          dataPrismic,
          SENTRY_ACTIONS.RESTART_SUBSCRIPTION
        )
        // Turn off loading indicator and popup
        handleAfterRestartSubscription(turnOffProcessing)

        return
      }

      const newSubscription =
        (response && response.data && response.data.restartSubscription) || {}
      const newProduct = newSubscription.product || {}

      // LA-1407: Show again rollover subscription if continue current active subscription and rollover is turned on
      if (
        isActiveRollover(newSubscription, userCountry) &&
        isEmpty(rolloverSubscription) &&
        !currentSubscription.next_product_handle
      ) {
        const rolloverSubsHandle =
          currentSubscription.product &&
          currentSubscription.product.rollover_product_handle
        getNextProduct(undefined, user.addressCountry, rolloverSubsHandle)
      }

      setCurrentSubscription({
        ...newSubscription,
        product_handle: newProduct.product_handle,
        total_price_in_cents,
        product: {
          ...newProduct,
        },
      })
      removeParamRestart()
      setIsOpenedRestartSubscription(false)
      setResponseStatus({
        type: "success",
        message: (
          <RichText
            render={restart_subscription_success?.raw}
            linkResolver={linkResolver}
          />
        ),
      })

      // update lmodSubscription on localstorage
      // set delayed_cancel_at null when restart subscription
      const savedUser = getLocalStorage(USER_INFO_KEY)
      const lmodSubscription = {
        ...savedUser.lmodSubscription,
        delayed_cancel_at: null,
      }

      setLocalStorage(USER_INFO_KEY, {
        ...savedUser,
        lmodSubscription,
      })
    } catch (err) {
      handleErrorWithPrismic(
        err.errors || [],
        restart_subscription_error.text,
        setResponseStatus,
        dataPrismic,
        SENTRY_ACTIONS.RESTART_SUBSCRIPTION
      )

      // Turn off loading indicator and popup
      handleAfterRestartSubscription(turnOffProcessing)
    }
  }

  // Only used for restart suspended subscription
  const handleAfterRestartSubscription = turnOffProcessing => {
    // Turn off loading indicator and popup
    turnOffProcessing(false)
    setIsOpenedRestartSubscription(false)
    scrollToTop()
  }

  /**
   * Change subscription
   */
  const changeSubscriptionType = async (
    subscription,
    handleError,
    // nonce,
    callback = () => {}
  ) => {
    try {
      const response = await API.graphql(
        graphqlOperation(changeSubscriptionChargifyJs, {
          product_handle: subscription.product_handle,
        })
      )
      setIsProcessing(false)

      // Handle errors with prismic data
      if (response.errors && response.errors.length > 0) {
        handleErrorWithPrismic(
          response.errors,
          change_subscription_error.text,
          handleError,
          changeSubscriptionData,
          SENTRY_ACTIONS.CHANGE_SUBSCRIPTION_CHARGIFY_JS
        )

        return
      }

      setNextSubscription(
        isOpenedContinueCurrentSubscription ? {} : subscription
      )

      // update lmodSubscription on localstorage
      const savedUser = getLocalStorage(USER_INFO_KEY)
      const lmodSubscription = {
        ...savedUser.lmodSubscription,
        // set next_product_handle is null when continue current subscription
        // update next_product_handle when change subscription
        next_product_handle: isOpenedContinueCurrentSubscription
          ? null
          : subscription.product_handle,
      }

      setLocalStorage(USER_INFO_KEY, {
        ...savedUser,
        lmodSubscription,
      })

      callback()
    } catch (err) {
      setIsProcessing(false)
      handleErrorWithPrismic(
        err.errors || [],
        change_subscription_error.text,
        handleError,
        changeSubscriptionData,
        SENTRY_ACTIONS.CHANGE_SUBSCRIPTION_CHARGIFY_JS
      )
      return
    }
  }

  const handleSubmitChangeSubscription = (
    subscription,
    handleError,
    callback = () => {}
  ) => {
    setIsProcessing(true)
    changeSubscriptionType(subscription, handleError, callback)
  }

  const handleContinueCurrentSubscription = () => {
    handleSubmitChangeSubscription(
      currentSubscription,
      err => setResponseStatus(err),
      () => {
        const savedUser = getLocalStorage(USER_INFO_KEY)

        // LA-1407: Show again rollover subscription if continue current active subscription and rollover is turned on
        if (isActiveRollover(currentSubscription, userCountry)) {
          const rolloverSubsHandle =
            currentSubscription.product &&
            currentSubscription.product.rollover_product_handle
          getNextProduct(
            undefined,
            savedUser.addressCountry,
            rolloverSubsHandle
          )
        }

        setResponseStatus({
          type: "success",
          message: (
            <p>
              {continue_current_subscription_success.text.replace(
                "@next_billing_date",
                formatDateTime(
                  current_period_ends_at,
                  DATETIME_FORMAT.default,
                  layoutData
                )
              )}
            </p>
          ),
        })
        setIsOpenedContinueCurrentSubscription(false)
      }
    )
  }

  const renderCancelledSubscriptionNotification = subscription => {
    if (isOnHoldSubscription(subscription)) {
      // Render notification for on hold status
      setResponseStatus({
        type: "error",
        isOnHold: true,
      })
      return
    }

    let cancelledSubscriptionNotification

    // subscription's state is cancelled
    if (isImmediatelyCanceled(subscription)) {
      cancelledSubscriptionNotification =
        fully_cancelled_subscription_notification.text
    } else {
      const cancelledSubscriptionNotificationPrismic = isResellerCustomer
        ? suspended_subscription_notification_rc
        : cancelled_subscription_notification
      cancelledSubscriptionNotification = cancelledSubscriptionNotificationPrismic.text.replace(
        "@expired_date",
        formatDateTime(
          subscription.current_period_ends_at,
          DATETIME_FORMAT.default,
          layoutData
        )
      )
    }

    setResponseStatus({
      type: "error",
      message: renderReminder(
        cancelledSubscriptionNotification,
        () =>
          isImmediatelyCanceled(subscription)
            ? // User need to be navigated to my. confirm plan page if sub was cancelled
              process.env.GATSBY_RT_26_07_2022_WINBACK === "true"
              ? isBrowser &&
                (window.location.href = `${process.env.GATSBY_GETTING_STARTED_URL}signup/select-plan`)
              : navigate(ROUTES(lang).MEMBERSHIP)
            : setIsOpenedRestartSubscription(true),
        isImmediatelyCanceled(subscription)
          ? restart_subscription_label.text
          : restart_subscription_now_label.text,
        false,
        isImmediatelyCanceled(subscription)
      ),
    })
  }

  const renderReminder = (
    reminder,
    handleOnClick,
    buttonLabel,
    disabledBtn,
    isActuallyCanceled
  ) => (
    <>
      <span>{reminder}</span>
      &nbsp;
      {isResellerCustomer && !isActuallyCanceled ? null : (
        <Button
          className="underline cursor-pointer inline"
          handleOnClick={handleOnClick}
          disabled={disabledBtn}
        >
          {buttonLabel}
        </Button>
      )}
    </>
  )

  const handleUpdateSubscriptionPreferences = async (
    callback,
    allowRollover
  ) => {
    setIsProcessing(true)

    try {
      const { errors, data } = await API.graphql(
        graphqlOperation(updateSubscriptionPreference, {
          rolloverOption: allowRollover,
        })
      )

      setIsProcessing(false)

      if (errors && errors.length > 0) {
        handleErrorWithPrismic(
          errors,
          lmod_usp_err.text,
          setResponseStatus,
          dataPrismic
        )
        return
      }

      // Turn on/off toggle
      callback()

      // const default_to_monthly = data.updateSubscriptionPreference.default_to_monthly

      // Update current subscription
      const updatedSubscription = {
        ...currentSubscription,
        default_to_monthly:
          data.updateSubscriptionPreference.default_to_monthly,
      }

      const savedUser = getLocalStorage(USER_INFO_KEY)

      // LA-1407: Only get rollover subscription if turn on and active subs
      if (
        Object.keys(rolloverSubscription).length === 0 &&
        isActiveRollover(updatedSubscription, userCountry)
      ) {
        const rolloverSubsHandle =
          updatedSubscription.product &&
          updatedSubscription.product.rollover_product_handle
        getNextProduct(undefined, savedUser.addressCountry, rolloverSubsHandle)
      }
      setCurrentSubscription(updatedSubscription)
      setLocalStorage(USER_INFO_KEY, {
        ...savedUser,
        lmodSubscription: updatedSubscription,
      })
    } catch (err) {
      setIsProcessing(false)
      handleErrorWithPrismic(
        err && err.errors ? err.errors : [err],
        lmod_usp_err.text,
        setResponseStatus,
        dataPrismic
      )
    }
  }

  const handleDismissSuspendedModal = () => {
    setIsOpenedSubscriptionSuspended(false)
    if (window.usabilla_live) {
      window.usabilla_live("trigger", "cancellation")
    }
  }
  const signupChannel = user.signupChannel
    ? user.signupChannel.toLowerCase()
    : null
  const jwtToken = isBrowser && window?.localStorage?.getItem(JWT_TOKEN)
  const userInfoJwt = parseJwt(jwtToken)
  const isPremiumTier = userInfoJwt?.tierId?.includes("premium")

  const validateMemberPromo = useCallback(async () => {
    const currentSubscriptionProductName = getConvertedPlanPeriod(
      currentSubscription?.product?.product_price_point?.interval,
      currentSubscription?.product?.product_price_point?.interval_unit
    )

    const firstOfferData = memberPromoPopupData?.first_offer?.length
      ? getOffer(
          memberPromoPopupData?.first_offer,
          currentSubscriptionProductName
        )
      : {}

    client()
    if (firstOfferData?.promo_code) {
      setFirstMemberPromoCode(firstOfferData?.promo_code)
      try {
        const { data } = await API.graphql(
          graphqlOperation(getPromoCodeInformation, {
            promoCode: firstOfferData?.promo_code,
          })
        )

        if (data?.getPromoCodeInformation) {
          setMemberPromoIsValid(true)
        } else {
          setMemberPromoIsValid(false)
        }
      } catch (error) {
        captureException({
          action: SENTRY_ACTIONS.MEMBER_PROMO_VALIDATION,
          ...error,
        })
        setMemberPromoIsValid(false)
      }
    }
  }, [currentSubscription])

  const FULLY_MEMBER_PROMO_JOURNEY_COUNTRIES = ["gb", "au", "ca"]
  const isMemberPromoDisplayed = getLocalStorage(IS_MEMBER_PROMO_DISPLAYED)
  const isFullyMemberPromoJourneyCountry = FULLY_MEMBER_PROMO_JOURNEY_COUNTRIES.includes(
    userCountry?.toLowerCase()
  )
  const isFullyMemberPromoJourney =
    memberPromoIsValid &&
    isMemberPromoDisplayed !== "true" &&
    isFullyMemberPromoJourneyCountry
  const isSimplifiedMemberPromoJourney =
    memberPromoIsValid &&
    isMemberPromoDisplayed !== "true" &&
    !isFullyMemberPromoJourneyCountry &&
    display_offer_button

  return (
    <Container
      title={title.text}
      classNames={{
        wrapper: ` max-w-900 ${loading ? "h-full-wrapper" : ""} md:px-20`,
      }}
    >
      {renderNotificationToast()}
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <PersonalInfoSection
            prismicData={personalPrismicData}
            sectionStyles={sectionStyles}
            error={get_personal_information_error.text}
            user={userData}
            lang={lang}
          />
          {process.env.GATSBY_RT_28_08_2023_UPDATE_FITNESS_PROFILE === "true" &&
            isPremiumTier && (
              <FitnessProfileSection
                sectionStyles={sectionStyles}
                data={dataPrismic}
              />
            )}
          {(currentPaymentMethod || isResellerCustomer) && !isIAPSubs && (
            <PaymentDetailSection
              paymentDetailSection={paymentDetailSection}
              sectionStyles={sectionStyles}
              paymentMethod={currentPaymentMethod || {}}
              lang={lang}
              isResellerUser={isReseller}
              isResellerCustomerUser={isResellerCustomer}
            />
          )}
          <EmailReferencesSection
            emailReferencesSection={emailReferencesSection}
            newsletterMessage={registered_message.text}
            sectionStyles={sectionStyles}
            user={userData}
            data={dataPrismic}
          />
          {/* AB2B-695: ApplePay users are considered active sub, but lmosSubscription is null */}
          {((currentSubscription &&
            Object.keys(currentSubscription).length > 0) ||
            isIAPSubs) && (
            <SubscriptionDetailSection
              isIAP={isIAPSubs}
              signupChannel={signupChannel}
              isNotIncludedTax={isNotIncludedTax}
              plusTaxText={plusTaxText}
              subscriptionDetailSection={{
                ...subscriptionDetailSection,
                restart_subscription_reminder,
              }}
              currentSubscription={currentSubscription}
              btnRestartSubscriptionLabel={restart_subscription_label}
              btnCancelSubscriptionLabel={cancel_subscription_button_label}
              handleSubscription={handleSubscription}
              handleDeleteSubscription={handleDeleteSubscription}
              isSubscriptionCanceled={isSubscriptionCanceled}
              sectionStyles={sectionStyles}
              dataPrismic={dataPrismic}
              nextSubscription={nextSubscription}
              rolloverSubscription={rolloverSubscription}
              handleOpenContinueCurrentSubscription={
                setIsOpenedContinueCurrentSubscription
              }
              renderReminder={renderReminder}
              lang={lang}
              layoutData={layoutData}
              isProcessing={isProcessing}
              handleUpdateSubscriptionPreferences={
                handleUpdateSubscriptionPreferences
              }
              userCountry={userCountry}
              isResellerUser={isReseller}
              isResellerCustomerUser={isResellerCustomer}
            />
          )}
        </>
      )}
      {currentSubscription &&
        Object.keys(currentSubscription).length > 0 &&
        isOpenedRestartSubscription && (
          <RestartSubscription
            isIAP={isIAPSubs}
            isNotIncludedTax={isNotIncludedTax}
            isEntitledToFreeTrial={userData?.isEntitledToFreeTrial}
            plusTaxText={plusTaxText}
            handleDismiss={() => {
              removeParamRestart()
              setIsOpenedRestartSubscription(false)
            }}
            subscription={currentSubscription}
            handleOpenChangeSubscriptionType={() => {
              setIsOpenedRestartSubscription(false)
              setIsOpenedChangeSubscriptionType(true)
            }}
            data={dataPrismic}
            handleRestartSubscription={handleRestartSubscription}
            paymentMethod={currentPaymentMethod || {}}
            nextSubscription={nextSubscription}
            layoutData={layoutData}
          />
        )}
      {!isReseller &&
        currentSubscription &&
        Object.keys(currentSubscription).length > 0 &&
        isOpenedChangeSubscriptionType && (
          <ChangeSubscriptionTypeModal
            handleDismiss={() => {
              setIsOpenedChangeSubscriptionType(false)
              setIsOpenedRestartSubscription(true)
            }}
            currentSubscription={currentSubscription}
            availableSubscriptions={availableSubscriptions}
            data={changeSubscriptionData}
            handleSubmitModal={handleSubmitChangeSubscription}
            layoutData={layoutData}
            isNotIncludedTax={isNotIncludedTax}
            plusTaxText={plusTaxText}
          />
        )}
      {!isReseller && isOpenedCancelSubscription && (
        <CancellationConfirmModal
          cancelSubscriptionData={cancelSubscriptionData}
          progressValue={50}
          handleDismiss={() => setIsOpenedCancelSubscription(false)}
          handleCancelSubscription={handleCancelSubscription}
          handleDisplayMemberPromoModal={() => {
            if (isFullyMemberPromoJourney) {
              setIsOpenedMemberOfferModal(true)
              setIsOpenedCancelSubscription(false)
            } else {
              handleCancelSubscription()
            }
          }}
          isProcessing={isProcessing}
          lang={lang}
          isImmediatelyCanceled={isImmediatelyCanceled(currentSubscription)}
          questionButtons={questionButtons}
          user={userData}
          isSimplifiedMemberPromoJourney={isSimplifiedMemberPromoJourney}
          memberPromoCode={firstMemberPromoCode}
        />
      )}
      {!isReseller && !isResellerCustomer && isOpenedSubscriptionSuspended && (
        <CancellationSuspendedModal
          cancelSubscriptionData={cancelSubscriptionData}
          progressValue={100}
          suspendedData={{
            email: userData.email,
            expirationDate: formatDateTime(
              currentSubscription.current_period_ends_at,
              DATETIME_FORMAT.default,
              layoutData
            ),
          }}
          handleDismiss={() => handleDismissSuspendedModal()}
          questionButtons={questionButtons}
          isImmediatelyCanceled={isImmediatelyCanceled(currentSubscription)}
          lang={lang}
          handleOpenRestartSubscription={() =>
            setIsOpenedRestartSubscription(true)
          }
        />
      )}
      {!isReseller && isOpenedContinueCurrentSubscription && (
        <Popup
          handleDismiss={() => setIsOpenedContinueCurrentSubscription(false)}
          handleSubmit={handleContinueCurrentSubscription}
          btnRejectLabel={continue_current_subscription_no_button_label.text}
          btnSubmitLabel={continue_current_subscription_yes_button_label.text}
          isDisabledBtns={isProcessing}
        >
          <p className="font-base-light text-gray-800 leading-snug md:leading-7none md:text-2lg text-base text-center mb-40 text-3xs mt-60 md:mt-12">
            {continue_current_subscription_confirm_message.text.replace(
              "@current_subscription",
              product.name
            )}
          </p>
        </Popup>
      )}
      {isFullyMemberPromoJourney && isOpenedMemberOfferModal && (
        <MemberPromoModal
          prismicData={memberPromoPopupData}
          currentSubscription={currentSubscription}
          handleDismiss={() => {
            setIsOpenedMemberOfferModal(false)
          }}
          handleCancelSubscription={handleCancelSubscription}
          isProcessing={isProcessing}
        />
      )}
    </Container>
  )
}

export default memo(MyAccount)
