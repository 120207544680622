export const MANUAL_ADDRESS_ID = {
  firstAddressId: "manual-first-address",
  secondAddressId: "manual-second-address",
  cityId: "manual-city",
  postalCodeId: "manual-postal-code",
  stateId: "manual-state",
  countryId: "manual-country",
}

export const MANUAL_ADDRESS_TARGETS = {
  lookupTargetState: "state",
  lookupTargetFullAddress: "address",
  lookupTargetPostalCode: "postalCode",
}
