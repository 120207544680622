export const cancelSubscription = `
  mutation cancelSubscription {
    cancelSubscription {
      user_id
      state
      previous_state
      reactive
      current_period_started_at
      current_period_ends_at
      balance_in_cents
      cancellation_method
      cancel_at_end_of_period
      canceled_at
      delayed_cancel_at
      receives_invoice_emails
      next_product_id
      next_product_handle
      tune_offer_id
      offer_id
      product_handle
      product {
        product_handle
        name
        description
        currency
        product_price_point {
          price_in_cents
          interval
          interval_unit
          trial_interval
          trial_interval_unit
          final_price_in_cents
          tax_in_cents
          final_tax_in_cents
        }
      }
    }
  }
`

export const immediateCancelSubscription = `
  mutation immediateCancelSubscription {
    immediateCancelSubscription
  }
`
