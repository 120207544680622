// flow
import React, { Node, memo } from "react"
import { Modal, FormTitle } from "@lesmills/gatsby-theme-common"

type Props = {|
  handleDismiss: () => void | Promise<void>,
  children: Node,
  title: string,
  classNames?: {
    wrapper: string,
  },
  testId?: string,
|}

const AccountManagementModal = ({
  handleDismiss,
  children,
  title,
  classNames = {},
  testId = "",
}: Props) => (
  <Modal
    handleDismiss={handleDismiss}
    classNames={{
      content: ` w-fit-content w-moz-fit-content md:h-max-content${
        classNames.content ? classNames.content : ""
      }`,
    }}
    testId={testId}
  >
    <div
      className={`md:my-30 md:mx-60 my-10 mx-0 ${
        classNames.wrapper ? classNames.wrapper : ""
      }`}
    >
      <FormTitle
        title={title}
        classNames="text-center"
        testId={testId ? `${testId}-title` : ""}
      />
      {children}
    </div>
  </Modal>
)

export default memo(AccountManagementModal)
