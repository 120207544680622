import React, { useEffect } from "react"

import { Helmet } from "react-helmet"

import type { ChangeSubscriptionPageType } from "../types/ChangeSubscriptionPageType"
import type { CancelSubscriptionPopupType } from "../types/CancelSubscriptionPopupType"
import type { MyAccountPageType } from "../types/MyAccountPageType"
import type { EmailNewsletterType } from "../types/EmailNewsletterType"
import type { MemberPromoPopupType } from "../types/MemberPromoPopupType"
import type { UserType, AllLayoutsType } from "@lesmills/gatsby-theme-common"

import {
  PrivateRoute,
  USER_INFO_KEY,
  getLocalStorage,
  identifyEvent,
} from "@lesmills/gatsby-theme-common"

import {
  renderSEO,
  Layout,
  ROUTES,
  DATA_LAYER_SCRIPT,
} from "@lesmills/gatsby-theme-common"

import { withPreview } from "gatsby-source-prismic"
import { graphql } from "gatsby"

import MyAccount from "../components/MyAccount"

type Props = {|
  pageContext: {
    lang: string,
  },
  data: {
    prismicMyAccountPage: {
      data: MyAccountPageType,
    },
    prismicChangeSubscriptionPage: {
      data: ChangeSubscriptionPageType,
    },
    prismicCancelSubscriptionPopups: {
      data: CancelSubscriptionPopupType,
    },
    prismicEmailNewsletter: {
      data: EmailNewsletterType,
    },
    prismicLayout: {
      data: AllLayoutsType,
    },
    prismicMemberPromoPopup: {
      data: MemberPromoPopupType,
    },
  },
|}

const MyAccountPage = (props: Props) => {
  const { pageContext, data } = props || {}
  const scriptsMyAccountPage = [DATA_LAYER_SCRIPT]

  const lang = pageContext.unPublishedLang || pageContext.lang

  useEffect(() => {
    // wait for window.analytics to be initalised
    const intervalId = setInterval(() => {
      const vimeoId = getLocalStorage(USER_INFO_KEY)?.vimeoUserId
      if (identifyEvent(vimeoId)) {
        clearInterval(intervalId)
      }
    }, 100)

    return () => clearInterval(intervalId)
  }, [])

  const {
    prismicLayout = {},
    prismicEmailNewsletter = {},
    prismicChangeSubscriptionPage = {},
    prismicMyAccountPage = {},
    prismicCancelSubscriptionPopups = {},
    prismicMemberPromoPopup = {},
  } = data || {}

  if (!data) return null

  const renderMyAccountPage = ({ user, checkingSession }: UserType) => {
    // scripts array injects third-party javascript into the page via react-helmet
    // if the CustomerIO siteID is configured for this environment, and if the logged-in user data exists, create the API link between customerIO and the page
    const scripts =
      process.env.GATSBY_RT_22_11_2023_CUSTOMERIO_SITE_ID &&
      user?.email &&
      user?.vimeoUserId
        ? [
            {
              type: "text/javascript",
              innerHTML: `var _cio = _cio || [];
            (function () {
              var a, b, c;
              a = function (f) {
                return function () {
                  _cio.push([f].concat(Array.prototype.slice.call(arguments, 0)));
                };
              };
              b = ['load', 'identify', 'sidentify', 'track', 'page'];
              for (c = 0; c < b.length; c++) {
                _cio[b[c]] = a(b[c]);
              }
              var t = document.createElement('script'),
                s = document.getElementsByTagName('script')[0];
              t.async = true;
              t.id = 'cio-tracker';
              t.setAttribute('data-site-id', '${
                process.env.GATSBY_RT_22_11_2023_CUSTOMERIO_SITE_ID
              }');
              t.setAttribute('data-use-in-app', 'true');
              t.src = 'https://assets.customer.io/assets/track.js';
              s.parentNode.insertBefore(t, s);
            })();
            _cio.identify({
              id: '${user?.vimeoUserId}',
              email: '${user?.email}',
              plan_name: '${user?.lmodSubscription?.product?.name || ""}',
            });
          `,
            },
          ]
        : []
    return (
      <Layout
        data={prismicLayout.data}
        lang={lang}
        user={user}
        isLoading={checkingSession}
      >
        <Helmet
          bodyAttributes={{
            class: "overscroll-none bg-grey-new-lighterer",
          }}
          script={scripts}
        />
        {renderSEO(
          prismicMyAccountPage.data,
          ROUTES(lang).CUSTOMER_ACCOUNT,
          lang,
          scriptsMyAccountPage
        )}
        <div id="customerioPromoMessage" />
        <MyAccount
          dataPrismic={prismicMyAccountPage.data}
          changeSubscriptionData={prismicChangeSubscriptionPage.data}
          cancelSubscriptionData={prismicCancelSubscriptionPopups.data}
          newsletterData={prismicEmailNewsletter.data}
          layoutData={prismicLayout.data}
          lang={lang}
          user={user}
          location={props.location}
          memberPromoPopupData={prismicMemberPromoPopup?.data}
        />
      </Layout>
    )
  }

  return <PrivateRoute component={renderMyAccountPage} lang={lang} {...props} />
}

export const query = graphql`
  query myAccountPageQuery($lang: String) {
    prismicEmailNewsletter(lang: { eq: $lang }) {
      data {
        registered_message {
          raw
          text
        }
      }
    }
    prismicChangeSubscriptionPage(lang: { eq: $lang }) {
      ...ChangeSubscriptionPage
    }
    prismicLayout(lang: { eq: $lang }) {
      data {
        required_state_notification {
          raw
        }
        rollover_preference_continue_message {
          text
        }
        rollover_preference_rollback_message {
          text
        }
        rollover_preference_question {
          text
        }
        inc_tax {
          text
        }
        inc_vat {
          text
        }
        if_applicable {
          text
        }
        plus_tax_label {
          text
        }
        sign_in_button {
          raw
          text
        }
        sign_out_button {
          raw
          text
        }
        logo {
          alt
          url
        }
        ...LayoutUnits
        body {
          ...LayoutBodyNavigation
        }
        body1 {
          ...LayoutBody1Navigation
        }
        body3 {
          ...LayoutBody3Countries
        }
        body2 {
          ...LayoutBody2Months
        }
        lmod_gu_err {
          text
        }
      }
    }
    prismicMyAccountPage(lang: { eq: $lang }) {
      data {
        applepay_message {
          text
        }
        applepay_subscription {
          text
        }
        vimeo_roku_subscription {
          text
        }
        vimeo_roku_message {
          text
        }
        vimeo_amazon_fire_subscription {
          text
        }
        vimeo_amazon_fire_message {
          text
        }
        vimeo_subscription {
          text
        }
        vimeo_message {
          text
        }
        custom_amazon_subscription {
          text
        }
        custom_amazon_message {
          text
        }
        custom_google_subscription {
          text
        }
        custom_google_message {
          text
        }
        one_moment {
          raw
          text
        }
        newsletters_disabled_message {
          raw
          text
        }
        email_sent_message {
          raw
          text
        }
        resend_email_message {
          raw
          text
        }
        active {
          raw
          text
        }
        body {
          ... on PrismicMyAccountPageBodyUserInfo {
            items {
              description {
                raw
                text
              }
              name {
                raw
                text
              }
            }
            primary {
              section_title {
                raw
                text
              }
              update_url {
                raw
                text
              }
            }
          }
        }
        body1 {
          ... on PrismicMyAccountPageBody1SubscriptionFields {
            items {
              subscription_field {
                raw
                text
              }
            }
          }
        }
        cancel_button_label {
          raw
          text
        }
        cancel_subscription_button_label {
          raw
          text
        }
        cancel_subscription_error {
          raw
          text
        }
        canceled {
          raw
          text
        }
        cancelled_subscription_notification {
          raw
          text
        }
        suspended_subscription_notification_rc {
          raw
          text
        }
        change_subscription_success {
          raw
          text
        }
        change_subscription_type {
          raw
          text
        }
        changed_subscription_reminder {
          raw
          text
        }
        changed_subscription_reminder_rc {
          raw
          text
        }
        continue_current_subscription_confirm_message {
          raw
          text
        }
        continue_current_subscription_error {
          raw
          text
        }
        continue_current_subscription_label {
          raw
          text
        }
        continue_current_subscription_no_button_label {
          raw
          text
        }
        continue_current_subscription_success {
          raw
          text
        }
        continue_current_subscription_yes_button_label {
          raw
          text
        }
        email_address_label {
          raw
          text
        }
        failed {
          raw
          text
        }
        fully_cancelled_subscription_notification {
          raw
          text
        }
        get_personal_information_error {
          raw
          text
        }
        inc_tax {
          raw
          text
        }
        include_tax {
          raw
          text
        }
        lmod_cs4h_err {
          text
        }
        lmod_dsdcs_err {
          text
        }
        lmod_gp_err {
          text
        }
        lmod_gs_err {
          text
        }
        lmod_gu_err {
          text
        }
        lmod_gus_err {
          text
        }
        lmod_ibct_err {
          text
        }
        lmod_lpbc_err {
          text
        }
        lmod_rdcs_err {
          text
        }
        lmod_sseg_err {
          html
          text
          raw
        }
        lmod_sus_err {
          text
        }
        lmod_ucdsu_err {
          text
        }
        lmod_uchc_err {
          text
        }
        lmod_uchchttp_err {
          text
        }
        lmod_ucu_err {
          text
        }
        lmod_usp_err {
          text
        }
        lmod_vbc_err {
          text
        }
        name_label {
          raw
          text
        }
        on_hold {
          raw
          text
        }
        on_hold_cancelled_subscription_notification {
          raw
          text
        }
        overdue_payment_label {
          raw
          text
        }
        fitness_profile_title
        fitness_profile_description
        update_fitness_profile_link_text
        page_author {
          text
        }
        page_description {
          text
        }
        page_image {
          alt
          url
        }
        page_keywords {
          text
        }
        page_og_description {
          text
        }
        page_og_title {
          text
        }
        page_og_type {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
        password_label {
          raw
          text
        }
        payment_note {
          raw
          text
        }
        payment_note_with_trial {
          raw
          text
        }
        payment_note_without_trial {
          raw
          text
        }
        pending_payment_label {
          raw
          text
        }
        processing {
          raw
          text
        }
        restart_subscription_error {
          raw
          text
        }
        restart_subscription_label {
          raw
          text
        }
        restart_subscription_now_label {
          raw
          text
        }
        restart_subscription_reminder {
          raw
          text
        }
        restart_subscription_success {
          raw
          text
        }
        restart_subscription_title {
          raw
          text
        }
        resume_subscription_success {
          raw
          text
        }
        retry_subscription_button_label {
          raw
          text
        }
        rollover_preferences_off_description {
          raw
          text
        }
        rollover_preferences_on_description {
          raw
          text
        }
        rollover_preferences_title {
          raw
          text
        }
        save_button_label {
          raw
          text
        }
        suspended {
          text
          raw
        }
        title {
          raw
          text
        }
        trialing {
          raw
          text
        }
      }
    }
    prismicCancelSubscriptionPopups(lang: { eq: $lang }) {
      data {
        back_button_label {
          text
          raw
        }
        close_button_label {
          raw
          text
        }
        continue_button_label {
          raw
          text
        }
        keep_button_label {
          raw
          text
        }
        offer_button_label {
          raw
          text
        }
        display_offer_button
        lmod_cs_err {
          raw
          text
        }
        lmod_gs_err {
          raw
          text
        }
        lmod_gus_err {
          raw
          text
        }
        lmod_ics_err {
          raw
          text
        }
        lmod_sus_err {
          raw
          text
        }
        no_button_label {
          raw
          text
        }
        skip_button_label {
          raw
          text
        }
        submit_button_label {
          raw
          text
        }
        yes_button_label {
          raw
          text
        }
        question_text {
          raw
          text
        }
        answer_text {
          raw
          text
        }
        confirmation_button {
          raw
          text
        }
        suspended_title {
          raw
          text
        }
        suspended_content {
          raw
          text
        }
      }
    }
    prismicMemberPromoPopup(lang: { eq: $lang }) {
      data {
        first_offer {
          banner_image {
            url
            mobile {
              url
            }
          }
          offer_description
          offer_label
          offer_term_and_conditions
          offer_title
          redeem_offer_button_label
          reject_offer_button_label
          subscription_type
          has_second_offer
          promo_code
        }
        second_offer {
          banner_image {
            url
            mobile {
              url
            }
          }
          offer_description
          offer_label
          offer_term_and_conditions
          offer_title
          redeem_offer_button_label
          reject_offer_button_label
          subscription_type
          promo_code
        }
      }
    }
  }
`

export default withPreview(MyAccountPage)
