export const getPromoCodeInformation = `
  query getPromoCodeInformation($promoCode: String!) {
    getPromoCodeInformation(promoCode: $promoCode) {
        type
        finalPriceInCentsTaxInclusive
        finalPriceInCentsTaxExclusive
        isRecurring
        productHandle
        discountPercentage
        discountAmount
    }
  }
`
