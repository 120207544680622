import {
  parseCountryCodeToText,
  getLocalStorage,
  USER_INFO_KEY,
  checkTimeAfterUpdatedPayment,
  CURRENT_COUNTRY,
  getSessionStorage,
  validatePostalCode,
  LOOKUP_ADDRESS_TARGET,
  isEmpty,
  // validateCountry,
} from "@lesmills/gatsby-theme-common"
import {
  MANUAL_ADDRESS_TARGETS,
  MANUAL_ADDRESS_ID,
} from "../components/BillingInformation/ManualAddressConstants"

import { AXII_CONFIGS } from "../../../../src/configs/axii-config.js"

// Handle format for lookup result with full address
export const formalizeLookupResult = (
  data = {},
  countries = [],
  states = [],
  parseStateField = "code",
  isStateRequired = false
) => {
  const {
    address = "",
    address2 = "",
    secondsAddress = "",
    city = "",
    state = "",
    country = "",
  } = data

  return [
    address,
    address2 || "",
    secondsAddress,
    city,
    isStateRequired
      ? (state &&
          parseCountryCodeToText(state, states, parseStateField).state_name) ||
        ""
      : state,
    parseCountryCodeToText(country, countries).name,
  ]
    .filter(item => !!item)
    .join(", ")
}

// Handle format for response location details,
// with full information: address, state, city, postalCode, country
export const formalizeResponseLocation = (
  data = {},
  countries = [],
  states = [],
  parseStateField = "label",
  isStateRequired = false
) => {
  const {
    // From form input
    address = "",
    address2 = "",
    postalCode = "",
    state = "",
    stateCode = "",
    city = "",
    countryCode = "",

    // From user props
    addressStreet = "",
    addressStreet2 = "",
    addressLocality = "",
    addressRegion = "",
    addressCountry = "",
    addressPostalCode = "",
    addressStateCode = "",
  } = data
  const userCountry = getSessionStorage(CURRENT_COUNTRY)
  const addressResult = address || addressStreet || ""
  const address2Result = address2 || addressStreet2 || ""
  const cityResult = city || addressLocality || ""

  // https://lesmillsinternational.atlassian.net/browse/LA-1456
  // Only use addressStateCode to get state with migration acc in us, ca
  const stateResult = parseCountryCodeToText(
    addressStateCode || (stateCode ? stateCode : state) || addressRegion || "",
    states,
    parseStateField
  )

  const countryResult = (
    countryCode ||
    addressCountry ||
    userCountry ||
    ""
  ).toLowerCase()
  const postalCodeResult = postalCode || addressPostalCode || ""

  return {
    result: formalizeLookupResult(
      {
        address: addressResult,
        address2: address2Result,
        city: cityResult,
        state: isStateRequired
          ? stateResult.name || ""
          : state || addressRegion,
        country: countryResult,
      },
      countries,
      states,
      "",
      isStateRequired
    ),
    address: addressResult,
    address2: address2Result,
    postalCode: postalCodeResult,
    state: isStateRequired ? stateResult.label || "" : state || addressRegion,
    city: cityResult,
    country: countryResult,
  }
}

export const callbackForGetPlaceAutoComplete = (
  response,
  setLookupResult,
  setLookupErr,
  lookupErr,
  countryCode,
  setIsExpanded
) => {
  const placesResponse =
    response && response.data ? response.data.getPlaceAutocomplete : {}
  const place = placesResponse.place_id || placesResponse.description

  if (!place) {
    setLookupResult({
      placeAutoComplete: placesResponse,
    })

    // Always expand dropdown after call API successfully
    setIsExpanded(true)
    setLookupErr({
      ...lookupErr,
      fullAddress: "",
    })
    return false
  }

  return {
    ...placesResponse,
    countryCode,
  }
}

export const callbackForGetPlaceDetails = (
  response = {},
  countries = [],
  states = [],
  setPlaceDetails = () => {},
  setIsExpanded = () => {},
  setIsShowManualForm = () => {},
  isStateRequired = false,
  defaultZipCode = ""
) => {
  const { data } = response
  const placesResponse = response && data ? data.getPlaceDetails : {}
  const { state, city, postalCode, country, stateCode } = placesResponse
  const stateName = isStateRequired
    ? parseCountryCodeToText(stateCode, states, "state_short_name").name
    : state

  setPlaceDetails(
    formalizeResponseLocation(
      placesResponse,
      countries,
      states,
      "state_short_name",
      isStateRequired
    )
  )

  // Open manual form when response missed any fields
  if (
    !country ||
    // https://lesmillsinternational.atlassian.net/browse/LA-1438
    // postal code and city are always required
    (!defaultZipCode && !postalCode) ||
    !city ||
    // https://lesmillsinternational.atlassian.net/browse/LA-1438
    // state is required for us and ca
    (isStateRequired &&
      (!state || !stateCode || !stateName) &&
      states.length > 0)
  ) {
    setIsExpanded(false)
    setIsShowManualForm(true)
    return
  }
  setIsExpanded(false)

  return {
    ...placesResponse,
    state: stateName,
  }
}

export const getSavedUser = user => {
  // Get userInfo from localStorage
  const localStorageData = getLocalStorage(USER_INFO_KEY) || {}
  // If user was updated after 5s, we can get data directly from user
  // Otherwise, we will get from localStorage

  return isEmpty(localStorageData) ||
    checkTimeAfterUpdatedPayment(localStorageData, 5)
    ? user || {}
    : localStorageData
}

export const handleValidateManualAddress = ({
  firstAddressInput = "",
  cityInput = "",
  stateInput = "",
  postalCodeInput = "",
  // countryInput = "",
  setLookupErr = () => {},
  // countries = [],
  prismicData = {},
  lookupTarget = "",
  states = [],
  isSubmitting = false,
  lookupErr = {},
  isStateRequired = false,
  defaultZipCode,
}) => {
  const {
    state_required = {},
    city_required = {},
    postcode_invalid = {},
    postcode_required = {},
    address1_required = {},
    // country_required = [],
    // country_invalid = [],
  } = prismicData

  let invalidCityMsg = ""
  let invalidStateMsg = ""
  let invalidPostalCodeMsg = ""
  let invalidCountryMsg = ""
  let invalidAddressMsg = ""

  // Disable country for now
  // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-1038
  // if (!countryInput) {
  //   invalidCountryMsg = getPrismicText(country_required)
  // } else {
  //   if (!validateCountry(countryInput, countries)) {
  //     invalidCountryMsg = getPrismicText(country_invalid)
  //   }
  // }

  // Require postal code
  if (!postalCodeInput) {
    invalidPostalCodeMsg = postcode_required.text
  } else {
    if (!validatePostalCode(postalCodeInput)) {
      invalidPostalCodeMsg = postcode_invalid.text
    }
  }

  // Not required zipcode if country has default zipcode: uae
  if (defaultZipCode) {
    invalidPostalCodeMsg = ""
  }

  // LME-877: Required address 1
  if (!firstAddressInput) {
    invalidAddressMsg = address1_required.text
  }

  // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-340
  // The State field is optional for non state-required Axii countries
  if (isStateRequired && !stateInput && states.length > 0) {
    invalidStateMsg = state_required.text
  }

  // only require city when submit form
  if (!cityInput && isSubmitting) {
    invalidCityMsg = city_required.text
  }

  setLookupErr({
    fullAddress: "",
    firstAddress: invalidAddressMsg,
    postalCode: invalidPostalCodeMsg,
    city: invalidCityMsg,
    state: invalidStateMsg,
    country: invalidCountryMsg,
    match: lookupTarget ? "" : lookupErr.match,
  })

  return (
    !invalidAddressMsg &&
    !invalidPostalCodeMsg &&
    !invalidCityMsg &&
    !invalidStateMsg &&
    !invalidCountryMsg
  )
}

export const handleValidateFullAddress = (
  fullAddressInput = "",
  lookupErr = {},
  setLookupErr = () => {},
  prismicData = {}
) => {
  const { full_address_invalid = {} } = prismicData
  let invalidFullAddressMsg = ""

  // Require country and valid name
  if (!fullAddressInput) {
    invalidFullAddressMsg = full_address_invalid.text
  }

  setLookupErr({
    ...lookupErr,
    fullAddress: invalidFullAddressMsg,
  })

  return !invalidFullAddressMsg
}

export const handleBeforeSubmitManualForm = async (
  manualRef = {},
  placeDetails = {},
  handleLookupLocation = () => {},
  handleOnChange = () => {},
  states = [],
  countries = [],
  defaultZipCode = "",
  isNeedValidateStateZipCode = false
) => {
  const { stateRef, countryRef, postalCodeRef } = manualRef
  const stateInput = stateRef && stateRef.current && stateRef.current.value
  const countryInput =
    countryRef && countryRef.current && countryRef.current.value
  const postalCodeInput =
    postalCodeRef && postalCodeRef.current ? postalCodeRef.current.value : ""
  const eventTarget = getLocalStorage(LOOKUP_ADDRESS_TARGET)
  const targetId =
    eventTarget && eventTarget.target
      ? eventTarget.target.targetId
        ? eventTarget.target.targetId
        : eventTarget.target.getAttribute("id")
      : ""

  // Jira: https://lesmillsinternational.atlassian.net/browse/LA-1438
  // Only lookup location in US, Canada
  const lookupTarget = isNeedValidateStateZipCode
    ? getLookupTarget(
        targetId,
        stateInput,
        parseCountryCodeToText(countryInput, countries).value || "",
        postalCodeInput,
        placeDetails,
        states,
        defaultZipCode
      )
    : ""

  switch (lookupTarget) {
    case MANUAL_ADDRESS_TARGETS.lookupTargetPostalCode:
      return handleOnChange({
        target: {
          getAttribute: () => MANUAL_ADDRESS_ID.stateId,
        },
        relatedTarget: "submit",
      })

    case MANUAL_ADDRESS_TARGETS.lookupTargetState:
    case MANUAL_ADDRESS_TARGETS.lookupTargetFullAddress:
      return handleOnChange({
        target: eventTarget.target,
        relatedTarget: "submit",
      })

    default:
      return handleLookupLocation(undefined, true) // isSubmitting = true
  }
}

export const getLookupTarget = (
  targetId = "",
  stateInput = "",
  countryInput = "",
  postalCodeInput = "",
  placeDetails = {},
  states = [],
  defaultZipCode = ""
) => {
  if (targetId) {
    switch (true) {
      // Change state or country > lookup postal code
      case targetId === MANUAL_ADDRESS_ID.stateId ||
        targetId === MANUAL_ADDRESS_ID.countryId:
        return MANUAL_ADDRESS_TARGETS.lookupTargetPostalCode

      // Change postal code > lookup state
      case targetId === MANUAL_ADDRESS_ID.postalCodeId:
        return MANUAL_ADDRESS_TARGETS.lookupTargetState

      default:
        return ""
    }
  } else {
    // This will call in case submit button
    // Can't know field id, only base on placeDetails to compare change

    switch (true) {
      // Change state or country > lookup postal code
      case states.length > 0 &&
        (stateInput !== placeDetails.state ||
          countryInput !== placeDetails.country.toLowerCase()):
        return MANUAL_ADDRESS_TARGETS.lookupTargetPostalCode

      // Change postal code > lookup state
      case !defaultZipCode && postalCodeInput !== placeDetails.postalCode:
        return MANUAL_ADDRESS_TARGETS.lookupTargetState

      default:
        return ""
    }
  }
}

export const getLocationSuccessResponse = (
  result = {},
  inputs = {},
  states = [],
  currentCountry = ""
) => {
  const {
    addressStreet = "",
    addressPostalCode = "",
    addressLocality = "",
    addressRegion = "",
  } = result

  const {
    firstAddressInput = "",
    secondsAddressInput = "",
    postalCodeInput = "",
    cityInput = "",
    stateInput = "",
    countryInput = "",
  } = inputs

  return {
    address:
      addressStreet ||
      firstAddressInput ||
      (states.length === 0 ? firstAddressInput : ""),
    address2: secondsAddressInput,
    postalCode: addressPostalCode ? addressPostalCode : postalCodeInput,
    city: addressLocality || cityInput,
    state: addressRegion || stateInput,
    countryCode: currentCountry,
    country: countryInput,
  }
}

export const getRequestLocation = (
  userCountry = "",
  lang = "",
  lookupTarget = "",
  stateInput = "",
  postalCodeInput = "",
  states = []
) => {
  let requestInput = {
    country: userCountry,
    language: lang.split("-")[0],
  }

  // Lookup for postalCode by state
  if (lookupTarget === MANUAL_ADDRESS_TARGETS.lookupTargetPostalCode) {
    requestInput.postalCode = postalCodeInput
    requestInput.state =
      parseCountryCodeToText(stateInput, states, "label").name || ""
  }

  // Lookup for state by postal code
  if (lookupTarget === MANUAL_ADDRESS_TARGETS.lookupTargetState) {
    requestInput.postalCode = postalCodeInput
  }

  return requestInput
}

export const handleLookupFullAddress = (
  handleLookupLocationNoAddress,
  handleLookupLocationHasAddress,
  placeDetails = {},
  savedUser = {},
  streetAddressInputValue = "",
  userCountry = ""
) => {
  if (
    placeDetails &&
    placeDetails.country &&
    placeDetails.result === streetAddressInputValue
  ) {
    return {
      ...placeDetails,
      countryCode: placeDetails.country ? placeDetails.country : userCountry,
    }
  }

  if (
    !savedUser.addressPostalCode ||
    streetAddressInputValue !== placeDetails.result
  ) {
    return handleLookupLocationNoAddress(streetAddressInputValue)
  }

  return handleLookupLocationHasAddress(savedUser, streetAddressInputValue)
}

export const formalizePlaceDetails = (
  savedUser = {},
  countries = [],
  states = [],
  isStateRequired = false
) => {
  let formalizedResponse = formalizeResponseLocation(
    savedUser,
    countries,
    states,
    "state_short_name",
    isStateRequired
  )

  // Lookup more with state code if result is not matched
  if (states.length > 0 && !formalizedResponse.state) {
    formalizedResponse = formalizeResponseLocation(
      savedUser,
      countries,
      states,
      "state_code",
      isStateRequired
    )
  }

  if (states.length > 0 && !formalizedResponse.state) {
    formalizedResponse = formalizeResponseLocation(
      savedUser,
      countries,
      states,
      "label",
      isStateRequired
    )
  }

  return formalizedResponse
}

export const getCurrentCountry = (placeDetails, storageCountry) =>
  placeDetails && placeDetails.country ? placeDetails.country : storageCountry

export const checkNeedValidateStateZipCode = country =>
  !!country &&
  AXII_CONFIGS.needValidateStateZipcodeCountries.indexOf(
    country.toLowerCase()
  ) > -1

export const checkStandardSubscriptionCountry = country =>
  !!country &&
  AXII_CONFIGS.standardSubscriptionCountries.indexOf(country.toLowerCase()) > -1

export const checkStateRequiredCountry = country =>
  !!country &&
  AXII_CONFIGS.stateRequiredCountries.indexOf(country.toLowerCase()) > -1
